import { IUserLibraryGames } from '@ultra/shared';

import { DownloadProcess } from '../../shared/game-manager/game-download-manager/game-download-manager';
import { GameStatus } from '../../shared/game-status/game-status';
export class SetGames {
  public static type = '[Library Games] Set library games';

  constructor(public payload: IUserLibraryGames) {}
}
export class SetGameStatus {
  static readonly type = '[Library Games] Set Game status';
  constructor(
    public payload: {
      gameId: string;
      status: GameStatus;
      data?: DownloadProcess;
    }
  ) {}
}
