import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import iassign from 'immutable-assign';

import { IUserGameBranch } from '@ultra/shared';

import { DownloadProcess } from '../../shared/game-manager/game-download-manager/game-download-manager';
import { GameStatus } from '../../shared/game-status/game-status';

import { SetGames, SetGameStatus } from './library-games.actions';

export type GameData = { [key: string]: IUserGameBranch };

export type GameStatusData = {
  [key: string]: {
    status: GameStatus;
    data?: DownloadProcess;
  };
};
export interface ILibraryGamesState {
  data: GameData;
  status: GameStatusData;
  meta: {
    date: string;
  };
}

const INITIAL_STATE: ILibraryGamesState = { data: {}, status: {}, meta: { date: null } };

@State<ILibraryGamesState>({
  name: 'libraryGames',
  defaults: INITIAL_STATE,
})
@Injectable()
export class LibraryGamesState {
  @Selector([LibraryGamesState])
  static getLibraryGames(state: ILibraryGamesState): GameData {
    return state.data;
  }

  @Selector([LibraryGamesState])
  static getGameStates(state: ILibraryGamesState): GameStatusData {
    return state.status;
  }

  @Selector([LibraryGamesState])
  static getRequestDate(state: ILibraryGamesState) {
    return state.meta.date;
  }

  /**
   * First element of the array is the latest purchased game, since
   * BE returns the games in descending order by purchase date.
   */
  @Selector([LibraryGamesState.getLibraryGames])
  static getLastPurchasedGame(state: ILibraryGamesState, data: GameData): IUserGameBranch {
    return Object.values(data)[0];
  }

  @Selector([LibraryGamesState.getLastPurchasedGame, LibraryGamesState.getGameStates])
  static getLastPurchasedGameStatus(
    state: ILibraryGamesState,
    game: IUserGameBranch,
    gameStates: GameStatusData
  ): GameStatus | undefined {
    return gameStates[game.game.id]?.status;
  }

  @Action(SetGames)
  setLibraryGames({ setState, getState }: StateContext<ILibraryGamesState>, { payload }: SetGames) {
    const state = iassign(getState(), () => {
      const games = payload.data;
      const meta = payload.meta;
      const status = {};
      if (games.length === 0) {
        return { data: {}, meta, status };
      }
      const data = games.reduce((acc, item) => {
        return { ...acc, ...{ [item.game.id]: item } };
      }, {});
      return { data, status, meta };
    });
    setState(state);
  }

  @Action(SetGameStatus)
  setGameStatus({ getState, patchState }: StateContext<ILibraryGamesState>, { payload }) {
    patchState({
      status: {
        ...getState().status,
        [payload.gameId]: {
          status: payload.status,
          data: payload.data ?? null,
        },
      },
    });
  }
}
